import React from "react";

import Faq from "../../../../components/Faq/faq";
// import Componcontact from '../../../../components/Contact/contact-compon';
import Banner from "../../../../components/Bannersection/banner";
import Subcard from "../../../../components/Subpagecard/Subcard";
import brochuredesign from "./brochuredesign.json";
import visitingcard from "./visitingcard.json";
import logodesign from "./logodesign.json";
import logodesign2 from "./logodesign2.json";
import Clientlogos from "../../../../components/Clientlogo/clientlogos";
import Rightlogo from "../../../../components/Clientlogo/rightlogo";
import { Helmet } from "react-helmet";

function Design() {
  const pagename = [
    {
      pagetitle: "Design Works",
      subtitle: "DesignWorks",
    },
  ];
  // const logo=logodesign;
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What types of design services do you offer?",
      answer:
        "We offer a wide range of design services, including logo design, brochure design, business card design, website design, and more. Our goal is to provide comprehensive design solutions that meet your specific needs.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How do you ensure that the design reflects my brand identity?",
      answer:
        "Our design process begins with a thorough understanding of your brand, including its values, mission, and personality. We work closely with you to ensure that our designs align with your brand identity and effectively convey your message.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "What is the turnaround time for design projects?",
      answer:
        "The turnaround time can vary depending on the complexity of the project. We provide estimated timelines at the beginning of each project and strive to meet agreed-upon deadlines.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question:
        "Do you handle the printing of design materials, such as business cards and brochures?",
      answer:
        "Yes, we offer printing services for a variety of design materials. Our team ensures that the printing quality meets our high standards, and we provide you with the finished product.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question: "What design software and tools do you use?",
      answer:
        "Our designers are skilled in using industry-standard design software and tools to create high-quality designs. We stay updated with the latest design trends and technologies to deliver cutting-edge results.",
    },
    {
      Faq_id: "collapseSix",
      data_bs_target: "#collapseSix",
      question: "Can you create designs for both digital and print media?",
      answer:
        "Absolutely! We have experience in creating designs for both digital and print media. Whether you need designs for your website, social media, or printed materials, we've got you covered.",
    },
    {
      Faq_id: "collapseSeven",
      data_bs_target: "#collapseSeven",
      question: "How can I get started with a design project?",
      answer:
        "Getting started is easy. Simply contact us to discuss your project requirements, and our team will guide you through the design process, from concept to completion.",
    },
    {
      Faq_id: "collapseEight",
      data_bs_target: "#collapseEight",
      question:
        "Can you provide design services for both small businesses and large enterprises?",
      answer:
        "Yes, our design services are adaptable to businesses of all sizes. We have experience working with both small startups and large enterprises, tailoring our designs to meet their unique requirements.",
    },
    {
      Faq_id: "collapseNine",
      data_bs_target: "#collapseNine",
      question:
        "What information do you need from me before starting a design project?",
      answer:
        "Before starting a design project, we'll need information about your business, your target audience, your design preferences, any existing branding guidelines, and specific project requirements. The more details you provide, the better we can tailor our designs to your needs.",
    },
    {
      Faq_id: "collapseTen",
      data_bs_target: "#collapseTen",
      question: "Do you offer ongoing design support or retainer services?",
      answer:
        "Yes, we offer ongoing design support and retainer services for clients who require continuous design work. This can be beneficial for businesses that need a steady stream of design materials.",
    },
  ];
  const company = {
    name: "Design Works",
    url: "https://dgiworx.com.au/design",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Design Works | DGi WORX</title>
        <meta
          name="description"
          content="Discover DGi WORX's design works in Australia, offering creative design services including logo design, visiting card design, brochure design, and letterhead design. Elevate your brand with innovative and visually appealing design solutions tailored for businesses in Australia."
        />
        <meta
          name="keywords"
          content="affordable creative logo design agency, 
    best design of logo, best logo design company uk, best logo design for food, 
    design works, creative design, logo design, visiting card design, brochure design, 
    letterhead design, Australian design services, DGi WORX design, visual identity, 
    brand design, graphic design, innovative design, professional branding, 
    business collateral design, print design, digital design, brand development"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="Design Works | DGi WORX" />
        <meta
          property="og:description"
          content="Discover DGi WORX's design works in Australia, offering creative design services including logo design, visiting card design, brochure design, and letterhead design. Elevate your brand with innovative and visually appealing design solutions tailored for businesses in Australia."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta property="og:url" content="https://dgiworx.com.au/design" />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/design_works/01.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/design_works/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-30 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                    Innovative Design Solutions
                  </span>
                  <h2 className="tp-section-title">
                    DESIGN WORKS
                    <br />
                  </h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      At DGi WORX, we recognize the profound impact of
                      exceptional graphic design. Our dedicated team of adept
                      designers is committed to delivering expert and impactful
                      design solutions for your business. Whether it's crafting
                      for print or digital media, we possess the proficiency to
                      craft captivating visuals that will elevate your brand and
                      captivate your audience.
                      <br />
                      <br />
                      We believe every brand has a unique narrative, and our
                      designers collaborate closely with you to showcase that
                      story through their designs. From creating compelling
                      brochures and eye-catching flyers to designing exquisite
                      packaging and crafting captivating business card layouts.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Professional Expertise
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Print and Digital Capabilities
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Unique Brand Storytelling
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Cutting-Edge Techniques
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Attention to Detail
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Effective Communication
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>
      {/* <!-- about-area-end --> */}

      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                  Discover Our Creative Portfolio
                </span>
                <h2 className="tp-section-title">Our Design Works</h2>
                <p>
                  Explore our diverse and inspiring portfolio of design
                  creations that showcase our passion for creativity and
                  excellence. Dive into our gallery to witness the artistry and
                  innovation that define our design journey.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row ">
          <div className="">
            <div className="container">
              <div className="sub-services">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/design_works/logo_design/logo_design.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">LOGO DESIGN</h4>
                          <p>
                            Crafting a robust brand identity is essential for
                            any business looking to carve its niche in a
                            competitive market. A well-crafted logo serves as
                            the cornerstone of this identity, serving as the
                            visual embodiment of your brand's values, mission,
                            and personality, resonating with your target
                            audience. At DGi WORX, our team of seasoned
                            designers comprehends the pivotal role of a
                            distinctive and memorable logo in enhancing your
                            brand's connection with customers.
                            <br />
                            <br />
                            We combine design principles, in-depth market
                            research, and your unique business objectives to
                            fashion a logo that not only boasts aesthetic appeal
                            but also authentically mirrors your brand. With DGi
                            WORX, you can trust that your logo will be a
                            compelling and accurate representation of your brand
                            identity, leaving a lasting impression on your
                            audience.
                          </p>
                        </div>
                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section className="feature-area tp-feature-5-wrap py-50 ">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="tp-section text-center mb-20">
                      <span className="tp-section-sub-title">
                        Explore Our Latest Logo Creations
                      </span>
                      <h2 className="tp-section-title">
                        Our Recent Logo Design Works
                      </h2>
                      <p>
                        Dive into our showcase of the most recent additions to
                        our portfolio, highlighting our expertise in crafting
                        captivating and contemporary logos. Explore these
                        emblematic creations that reflect our dedication to
                        visual excellence.
                      </p>
                    </div>
                  </div>
                </div>

                {/* <div className='mt-50'>
                    <Subcard item={logodesign}/>
                  </div> */}
              </div>
              <div className="container-flu">
                <Clientlogos item={logodesign} />
                <Rightlogo item={logodesign2} />
              </div>
            </section>
            <section className="feature-area tp-feature-5-wrap py-50">
              <div className="container">
                <div className="sub-services ">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                      <div className=" text-center sub-service-carde mb-20">
                        <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                          <div className="corner-border"></div>
                          <img
                            src="assets/img/service/design_works/visiting_card_design/visiting_card_design.jpg"
                            loading="lazy"
                          />
                          <div className="bottom-corner"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                      <div>
                        <div className="tp-blog-item-content">
                          <div className="tp-blog-item-title sub-service mb-50">
                            <h4 className="">VISITING CARD DESIGN</h4>
                            <p>
                              Your business card serves as the initial
                              impression you leave on a potential client or
                              customer, making it a crucial element of your
                              professional image. At DGi WORX, we recognize the
                              pivotal role of a well-crafted and high-quality
                              business card. Our team of experts collaborates
                              closely with you to fashion a distinctive and
                              memorable design that encapsulates your brand's
                              essence.
                              <br />
                              <br />
                              We harness the latest in printing technology and
                              select premium card stock to ensure that your
                              cards not only boast impressive visuals but also
                              provide a tactile experience that leaves a lasting
                              impression. Whether you seek a sleek and classic
                              design or a bolder and more creative approach, our
                              printing store is equipped to assist you in
                              creating the perfect business card that represents
                              your brand effectively. Elevate your professional
                              image with DGi WORX business cards.
                            </p>
                          </div>

                          <div className="tp-blog-item-btn">
                            {/* <a href="blog-details.html.htm">Read More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="tp-section text-center mb-50">
                      <span className="tp-section-sub-title">
                        Explore Our Latest Business Card Creations
                      </span>
                      <h2 className="tp-section-title">
                        Our Recent Visiting Card Design Works
                      </h2>
                      <p>
                        Discover our most recent collection of meticulously
                        crafted visiting card designs that showcase our
                        dedication to creating memorable first impressions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10">
                  <Subcard item={visitingcard} />
                </div>
              </div>
            </section>

            <section className="feature-area tp-feature-5-wrap py-50">
              <div className="container">
                <div className="sub-services ">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 col-sm-12">
                      <div className=" text-center sub-service-carde mb-20">
                        <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                          <div className="corner-border"></div>
                          <img
                            src="assets/img/service/design_works/brochure_design/brochure_design.jpg"
                            loading="lazy"
                          />
                          <div className="bottom-corner"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 col-sm-12">
                      <div>
                        <div className="tp-blog-item-content">
                          <div className="tp-blog-item-title sub-service mb-30">
                            <h4 className="">BROCHURE DESIGN</h4>
                            <p>
                              Brochures are a powerful marketing tool that can
                              convey a wealth of information about your
                              products, services, or organization in a visually
                              appealing format. At DGi WORX, we recognize the
                              significance of well-crafted brochures as
                              versatile marketing assets. Our team of expert
                              designers specializes in creating stunning
                              brochure designs that captivate your target
                              audience and effectively communicate your message.
                              <br />
                              <br />
                              We collaborate closely with you to understand your
                              objectives, brand identity, and the specific
                              information you want to convey. Whether you need a
                              sleek and modern design, an elegant and
                              sophisticated layout, or something entirely
                              unique, our designers have the creativity and
                              expertise to bring your vision to life.
                              <br />
                              <br />
                              We also pay attention to the quality of printing
                              and materials to ensure that your brochures not
                              only look great on the screen but also in print.
                              Our goal is to create brochures that not only
                              inform but also leave a lasting impression on your
                              audience. Elevate your marketing collateral with
                              DGi WORX brochure designs.
                            </p>
                          </div>

                          <div className="tp-blog-item-btn">
                            {/* <a href="blog-details.html.htm">Read More</a> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container py-50">
                <div className="row justify-content-center">
                  <div className="col-lg-10 col-md-12">
                    <div className="tp-section text-center mb-50">
                      <span className="tp-section-sub-title">
                        Explore Our Latest Brochure Creations
                      </span>
                      <h2 className="tp-section-title">
                        Our Recent Brochure Design Works
                      </h2>
                      <p>
                        Dive into our portfolio showcasing our most recent
                        brochure design creations that exemplify our commitment
                        to crafting compelling marketing collateral. Explore
                        these fresh designs that reflect our dedication to
                        combining creativity and information to captivate your
                        audience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-20">
                  <Subcard item={brochuredesign} />
                </div>
              </div>
            </section>

            <div className="container">
              <div className="sub-services ">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/design_works/letterhead_design/letterhead_design.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">LETTER HEAD DESIGN</h4>
                          <p>
                            Your business stationery serves as a reflection of
                            your brand and plays a crucial role in setting the
                            tone for your communications. A professionally
                            designed letterhead can make a powerful initial
                            impression and contribute to building trust with
                            clients and customers. At DGi WORX, we recognize the
                            significance of polished office stationery, and we
                            provide comprehensive letterhead design and printing
                            services tailored to your unique requirements.
                            <br />
                            <br />
                            Our team of experts is adept at crafting custom
                            letterhead designs that seamlessly incorporate your
                            brand's colors and logo, ensuring that your
                            documents consistently project your brand identity.
                            We offer a diverse selection of paper options,
                            including standard, bond, textured, and recycled,
                            allowing you to choose the ideal fit for your needs.
                            With our letterhead services, you can expect
                            top-notch printing quality and swift turnaround
                            times, elevating the professionalism of your
                            business communications.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Design;
