import React from "react";
import Faq from "../../../../components/Faq/faq";
import Componcontact from "../../../../components/Contact/contact-compon";
import Banner from "../../../../components/Bannersection/banner";
import { Helmet } from "react-helmet";

function Migrationservices() {
  const pagename = [
    {
      pagetitle: "Migration Services",
      subtitle: "Migration Services",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What types of data migration services do you offer?",
      answer:
        "We offer a wide range of data migration services, including storage migration, database migration, application migration, and cloud migration. Our services are tailored to meet specific business needs.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How do you ensure data security during migration?",
      answer:
        "Data security is a top priority. We employ encryption, access controls, and other security measures to protect data during migration, ensuring it remains confidential and secure.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Will data migration disrupt our business operations?",
      answer:
        "We aim to minimize disruption during data migration. Planning and scheduling migrations during off-peak hours, along with rigorous testing, help ensure a smooth transition with minimal downtime.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question: "What is the typical duration of a data migration project?",
      answer:
        "The duration varies depending on the scope and complexity of the migration. Small-scale migrations may take days, while larger projects could span several weeks or months.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question: "Can you migrate data to and from cloud environments?",
      answer:
        "Yes, we specialize in cloud migration, both to and from cloud environments. We help clients transition to the cloud for scalability and cost-efficiency or migrate from one cloud provider to another.",
    },
    {
      Faq_id: "collapseSix",
      data_bs_target: "#collapseSix",
      question: "What benefits can my business expect from data migration?",
      answer:
        "Data migration can lead to improved data accessibility, enhanced system performance, better data organization, reduced operating costs, and increased security compliance, among other benefits.",
    },
    {
      Faq_id: "collapseSeven",
      data_bs_target: "#collapseSeven",
      question:
        "Do you offer customized migration plans for businesses with unique needs?",
      answer:
        "Yes, we provide tailored migration plans designed to meet the specific requirements and objectives of each business. We work closely with clients to ensure a seamless transition.",
    },
  ];
  const company = {
    name: "Migration Services",
    url: "https://dgiworx.com.au/migrationservices",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Migration Services | DGi WORX</title>
        <meta
          name="description"
          content="Explore DGi WORX's migration services in Australia, offering seamless transitions for storage migration, database migration, and application migration. Ensure a smooth transfer of data and applications with our expert migration solutions tailored for businesses in Australia."
        />
        <meta
          name="keywords"
          content="migration services,microsoft server migration, 
    storage migration tools, windows file server migration, migration db, 
    aws database migration service, data migration strategy, storage migration, 
    database migration, application migration, Australian migration solutions, 
    business application migration, DGi WORX migration, cloud migration, server migration"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="Migration Services | DGi WORX" />
        <meta
          property="og:description"
          content="Explore DGi WORX's migration services in Australia, offering seamless transitions for storage migration, database migration, and application migration. Ensure a smooth transfer of data and applications with our expert migration solutions tailored for businesses in Australia."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://dgiworx.com.au/migrationservices"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/migration_services/01.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/migration_services/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                    Seamless Transition Solutions
                  </span>
                  <h2 className="tp-section-title">MIGRATION SERVICES</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      Data migration can be a labyrinthine journey, involving
                      the seamless transfer of data from one system to
                      another—be it between on-premises and cloud environments,
                      among different cloud platforms, or even across various
                      versions of the same platform. At DGi WORX, we recognize
                      the complexities of data migration and stand ready to be
                      your guiding light. <br />
                      <br />
                      Our Migration Services are expertise-driven, empowering
                      you with secure and efficient data transfers, minimizing
                      downtime, and ensuring data availability when you need it
                      most. Whether you're managing a modest data transition or
                      orchestrating a grand-scale migration, we've got your
                      back.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Seamless Data Transfer
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Minimized Downtime
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Data Security
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Compatibility
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Scalability
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Expert Guidance
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                  Elevate Your Transition
                </span>
                <h2 className="tp-section-title">
                  Our Data Migration Services
                </h2>
                <p>
                  With DGi WORX as your trusted partner, embark on a journey of
                  data transition with confidence and ease, knowing that your
                  critical information is in expert hands.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/migration_services/storage_migration.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">STORAGE MIGRATION</h4>
                          <p>
                            In the digital landscape, data storage serves as a
                            cornerstone for modern businesses. As technology
                            evolves, the imperative of migrating data to newer,
                            more efficient storage mediums becomes increasingly
                            evident. Whether it involves the transition from
                            disks to cloud storage or the adoption of
                            cutting-edge storage systems, storage migrations are
                            pivotal in preserving data accessibility,
                            performance, and security.
                            <br />
                            <br />
                            At DGi WORX, we stand as your trusted partner,
                            enabling the seamless migration of your data to the
                            latest technology. Our mission is to ensure that you
                            can harness, manage, and leverage your data with
                            utmost efficiency and agility, unburdened by legacy
                            storage constraints.
                            <br />
                            <br />
                            Experience the freedom of fluid data mobility with
                            DGi WORX, where we navigate the complexities of
                            storage migration, empowering your business to stay
                            at the forefront of technology and data management.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/migration_services/database_migration.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">DATABASE MIGRATION</h4>
                          <p>
                            Database migration is a critical undertaking in the
                            evolving landscape of data management. It involves
                            the intricate process of transferring data from one
                            database system to another, often necessitated by
                            system upgrades, platform changes, or a shift to
                            more efficient database solutions. At DGi WORX, we
                            specialize in simplifying database migration,
                            ensuring the secure and efficient transfer of your
                            critical data assets.
                            <br />
                            <br />
                            Our expert team is equipped to handle a wide range
                            of database migration scenarios, including
                            migrations between on-premises and cloud databases,
                            database version upgrades, and transitioning to
                            advanced database technologies. We understand the
                            importance of preserving data integrity, minimizing
                            downtime, and optimizing performance during the
                            migration process.
                            <br />
                            <br />
                            With DGi WORX, you can navigate the complexities of
                            database migration with confidence, knowing that
                            your data will seamlessly transition to its new
                            home, ready to support your evolving business needs.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-10">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/migration_services/application_migration.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">APPLICATION MIGRATION</h4>
                          <p>
                            Application migration is a pivotal aspect of
                            modernizing IT infrastructure and ensuring that
                            software applications remain efficient, accessible,
                            and secure. At DGi WORX, we specialize in
                            facilitating the smooth transition of applications
                            across various platforms, ensuring minimal
                            disruption to your business operations.
                            <br />
                            <br />
                            Our seasoned team possesses the expertise to handle
                            diverse application migration scenarios, including
                            moving applications to the cloud, upgrading to newer
                            versions, and transitioning between different
                            operating systems. We understand the importance of
                            preserving application functionality, data
                            integrity, and user experience throughout the
                            migration process.
                            <br />
                            <br />
                            With DGi WORX as your partner, you can embark on
                            your application migration journey with confidence.
                            We work tirelessly to ensure that your applications
                            seamlessly adapt to their new environment, enabling
                            your organization to embrace innovation and stay
                            competitive.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact/> */}

      {/* contact end section */}
    </div>
  );
}

export default Migrationservices;
