import React from "react";
import Faq from "../../../../components/Faq/faq";
import Componcontact from "../../../../components/Contact/contact-compon";
import Banner from "../../../../components/Bannersection/banner";
import { Helmet } from "react-helmet";

function Cloudservices() {
  const pagename = [
    {
      pagetitle: "Cloud Services",
      subtitle: "Cloud Services",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question:
        "What are the benefits of using cloud services for my business?",
      answer:
        "Cloud services offer scalability, cost-efficiency, reliability, and flexibility. They allow you to access resources as needed, reduce infrastructure costs, improve reliability, and adapt to changing business demands quickly.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "What types of cloud services do you offer?",
      answer:
        "We offer a range of cloud services, including Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS). We also provide cloud migration, data storage, and application hosting services.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "How can cloud services benefit startups and small businesses?",
      answer:
        "Cloud services offer startups and small businesses the ability to scale their IT resources as they grow, without the need for large upfront investments in hardware. They provide cost-effective solutions and flexibility to adapt to changing needs.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question:
        " How do I ensure data privacy and compliance when using cloud services?",
      answer:
        "Cloud providers often offer compliance certifications and tools to help you meet data privacy regulations. We can assist in configuring your cloud environment to adhere to specific compliance requirements.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question: "How can I get started with your cloud services?",
      answer:
        "Getting started is easy. Contact us through our website, phone, or email to discuss your specific needs. We'll work with you to create a customized cloud strategy that aligns with your business objectives.",
    },
  ];

  const company = {
    name: "Cloud Services",
    url: "https://dgiworx.com.au/cloudservices",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };
  return (
    <div>
      <Helmet>
        <title>Cloud Services | DGi WORX</title>
        <meta
          name="description"
          content="Explore DGi WORX's cloud services in Australia, delivering cutting-edge cloud solutions. Our offerings include Infrastructure as a Service (IaaS), Platform as a Service (PaaS), Software as a Service (SaaS), and comprehensive cloud-based solutions for businesses in Australia."
        />
        <meta
          name="keywords"
          content="cloud services, 
    amazon cloud drive customer service, amazon cloud managed services, 
    best cloud server backup service, cloud solutions, Australian cloud computing, 
    cloud technology, cloud infrastructure, cloud-based services, 
    IaaS, PaaS, SaaS, DGi WORX cloud, cloud computing solutions, cloud service models, 
    cloud security, cloud migration, scalable cloud solutions, 
    cloud-based applications, cloud consultancy, 
    cloud platform, innovative cloud services"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="Cloud Services | DGi WORX" />
        <meta
          property="og:description"
          content="Explore DGi WORX's cloud services in Australia, delivering cutting-edge cloud solutions. Our offerings include Infrastructure as a Service (IaaS), Platform as a Service (PaaS), Software as a Service (SaaS), and comprehensive cloud-based solutions for businesses in Australia."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://dgiworx.com.au/cloudservices"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/cloud_services/01.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/cloud_services/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                    Unleash the Power of the Cloud
                  </span>
                  <h2 className="tp-section-title">CLOUD SERVICES</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      Cloud services have revolutionized the way businesses
                      operate, offering scalability, flexibility, and
                      cost-efficiency like never before. At DGi WORX, we harness
                      the potential of cloud technology to help businesses
                      thrive in the digital era. <br />
                      <br />
                      Our cloud services encompass a wide range of solutions,
                      including cloud migration, infrastructure management, data
                      storage, and application hosting. Whether you're looking
                      to migrate your existing infrastructure to the cloud,
                      optimize your cloud environment, or develop cloud-native
                      applications, our team of experts is here to guide you.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Scalability
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Cost-Efficiency
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Reliability and Redundancy
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Security
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Global Reach
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Automation and Management
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* service section */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                  Elevating Possibilities
                </span>
                <h2 className="tp-section-title">Our Cloud Services</h2>
                <p>
                  We're dedicated to unleashing the full potential of cloud
                  technology to empower businesses with scalability,
                  cost-efficiency, and unmatched flexibility.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="">
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/cloud_services/iaas.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">
                            INFRASTRUCTURE-AS-A-SERVICE (IAAS)
                          </h4>
                          <p>
                            Infrastructure as a Service (IaaS) serves as the
                            bedrock of cloud computing, empowering users to
                            effortlessly craft and oversee their virtual
                            infrastructure. Within the realm of IaaS, businesses
                            gain the agility to expand or contract their
                            computing resources in line with demand, while
                            paying solely for what they consume. This paradigm
                            offers an exceptional degree of control and
                            customization, affording businesses the liberty to
                            handpick their operating systems, software suites,
                            and hardware configurations. IaaS furnishes
                            businesses with a dependable and secure environment
                            to house and administer their data. <br />
                            <br />
                            As your partner in navigating the cloud landscape,
                            we invite you to explore the infinite possibilities
                            that IaaS can bring to your organization. With a
                            robust foundation like IaaS, businesses can unlock
                            new realms of scalability, cost-efficiency, and data
                            security.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/cloud_services/paas.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">PLATFORM-AS-A-SERVICE (PAAS)</h4>
                          <p>
                            Platform as a Service (PaaS) revolutionizes the
                            application development landscape, granting
                            developers the ability to expedite application
                            creation, testing, and deployment, all while leaving
                            behind the intricacies of infrastructure management.
                            By abstracting these complexities, developers are
                            liberated to direct their focus squarely on crafting
                            application code and refining business logic.
                            <br />
                            <br />
                            PaaS providers offer a spectrum of pre-built
                            services, including databases, application servers,
                            and development tools, ready for seamless
                            integration into applications. This translates into
                            accelerated time-to-market, trimmed development
                            expenses, and heightened agility for businesses.
                            <br />
                            <br />
                            As your gateway to streamlined application
                            development, PaaS empowers you to conquer the
                            challenges of modern development with ease, boosting
                            productivity, collaboration, and innovation.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-10">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/cloud_services/saas.jpg"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h4 className="">SOFTWARE-AS-A-SERVICE (SAAS)</h4>
                          <p>
                            Software as a Service (SaaS) reimagines software
                            delivery by entrusting third-party providers to host
                            applications, granting customers seamless access via
                            the internet. This liberates you to tap into the
                            power of software from virtually any corner of the
                            globe, as long as an internet connection is within
                            reach.
                            <br />
                            <br />
                            SaaS eliminates the burdens of software installation
                            and maintenance on your in-house servers or devices.
                            The service provider expertly manages all such
                            complexities, freeing you to channel your energies
                            into leveraging the software to fuel your business
                            operations. This simplicity makes SaaS an inherently
                            convenient and cost-effective solution, catering to
                            businesses of all dimensions.
                            <br />
                            <br />
                            As your gateway to streamlined software access and
                            utilization, SaaS empowers you to harness the full
                            potential of technology, driving efficiency and
                            productivity across your organization.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* service section end */}

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Cloudservices;
