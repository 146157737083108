import React, { useState } from "react";
import ReactFlagsSelect from "react-flags-select";
// import "react-flags-select/css/react-flags-select.css";

function Countryselect() {
  const [select, setSelect] = useState("AU");

  // Mapping of country codes to URLs
  const countryUrls = {
    US: "https://dgiworx.com",
    IN: "https://dgiworx.in",
    AU: "https://dgiworx.com.au",
    CA: "https://dgiworx.ca"
  };

  const onSelect = (code) => {
    setSelect(code);
    // Redirect to the website based on selected country
   //window.location.href = countryUrls[code];
   // Get the current pathname
   const path = window.location.pathname;

   // Construct the new URL based on the selected country and the current pathname
   const newUrl = countryUrls[code] + path;

   // Redirect to the new URL
   window.location.href = newUrl;
  };

  const customLabels = {
    AU: "AU",
    US: "US",
    IN: "IN",
    CA: "CA"
  };

  return (
    <div>
      <ReactFlagsSelect
        selected={select}
        onSelect={onSelect}
        countries={["AU", "US", "IN", "CA"]}
        customLabels={customLabels}
      />
    </div>
  );
}

export default Countryselect;
