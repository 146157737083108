import React from "react";
import Faq from "../../../../components/Faq/faq";
import Componcontact from "../../../../components/Contact/contact-compon";
import Banner from "../../../../components/Bannersection/banner";
import { Helmet } from "react-helmet";

function Dataanalytics() {
  const pagename = [
    {
      pagetitle: "Data Analytics",
      subtitle: "Data Analytics",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What types of data analytics services do you offer?",
      answer:
        "We provide a comprehensive range of data analytics services, including descriptive, diagnostic, predictive, and prescriptive analytics. These services help businesses understand their data, diagnose issues, predict outcomes, and prescribe optimal actions.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How can data analytics benefit my business?",
      answer:
        "Data analytics can benefit your business by providing insights into customer behavior, operational efficiency, cost reduction, and growth opportunities. It enables data-driven decision-making, leading to improved performance.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Can data analytics help with real-time decision-making?",
      answer:
        "Yes, data analytics can support real-time decision-making by processing and analyzing data as it's generated, allowing businesses to respond quickly to changing circumstances.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question: "Is data analytics suitable for businesses of all sizes?",
      answer:
        "Yes, data analytics can be tailored to suit businesses of all sizes. Whether you're a small startup or a large enterprise, data analytics can help you make informed decisions and optimize your operations.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question:
        "Is data analytics useful for industries beyond business, such as healthcare and government?",
      answer:
        "Absolutely, data analytics has applications in various industries, including healthcare for patient care optimization and government for policy analysis and decision-making.",
    },
    {
      Faq_id: "collapseSix",
      data_bs_target: "#collapseSix",
      question:
        "How do you ensure the quality and accuracy of data used in analytics projects?",
      answer:
        "We employ data cleansing, validation, and quality assurance processes to ensure the accuracy and reliability of data used in analytics projects.",
    },
    {
      Faq_id: "collapseSeven",
      data_bs_target: "#collapseSeven",
      question:
        "What are the key technologies and tools you use for data analytics?",
      answer:
        "We utilize a range of technologies and tools, including data warehousing, machine learning algorithms, data visualization platforms, and business intelligence software, tailored to each project's needs.",
    },
  ];

  const company = {
    name: "Data Analytics",
    url: "https://dgiworx.com.au/dataanalytics",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Data Analytics | DGi WORX</title>
        <meta
          name="description"
          content="Explore DGi WORX's data analytics services in Australia, providing actionable insights through advanced analytics solutions. Our offerings include data analysis, business intelligence, data visualization, and innovative analytics services tailored for businesses in Australia."
        />
        <meta
          name="keywords"
          content="data analytics, DGi WORX,
    analytics solutions, Australian data analysis, data visualization, 
    DGi WORX analytics, advanced analytics, data-driven decisions, 
    analytics services, data processing, predictive analytics, data modeling, 
    Australia data services, data strategy, data consultancy, innovative analytics, actionable insights"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="Data Analytics | DGi WORX" />
        <meta
          property="og:description"
          content="Explore DGi WORX's data analytics services in Australia, providing actionable insights through advanced analytics solutions. Our offerings include data analysis, business intelligence, data visualization, and innovative analytics services tailored for businesses in Australia."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://dgiworx.com.au/dataanalytics"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/data_analytics/01.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/data_analytics/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                    Unlocking Data Insights
                  </span>
                  <h2 className="tp-section-title">DATA ANALYTICS</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      DGi WORX is your trusted ally in the quest to harness the
                      full potential of your data. Our team of seasoned experts
                      offers a comprehensive suite of data analytics services,
                      tailored to cater to the unique needs of every business,
                      whether the requirements are simple or intricate.
                      <br />
                      <br />
                      We commence each journey by closely collaborating with our
                      clients to grasp their objectives and challenges,
                      meticulously crafting solutions to align with their
                      specific needs. Our data analytics services encompass data
                      integration, aggregation, and analysis from diverse
                      sources, granting businesses access to invaluable insights
                      for informed decision-making.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Data Integration
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Data Aggregation
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Informed Decision-Making
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Data Visualization
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Performance Tracking
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=""
                          loading="lazy"
                        />
                      </span>
                      Customized Solutions
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/project">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* <!-- about-area-end --> */}

      <section className="py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">Unveiling Insights</span>
                <h2 className="tp-section-title">
                  Our Data Analytics Services
                </h2>
                <p>
                  We offer a comprehensive suite of services that cater to the
                  unique needs of businesses, from simple analytics to complex
                  insights.
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="sub-services mb-50">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/service/data_analytics/predictive_data_analytics.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">PREDICTIVE DATA ANALYTICS</h4>
                        <p>
                          Predictive analytics stands as a dynamic instrument
                          that empowers businesses to harness the power of data
                          for future-focused decision-making. DGi WORX, armed
                          with the ability to analyze historical data and wield
                          statistical models, unveils patterns and trends that
                          illuminate the road ahead.
                          <br />
                          <br />
                          This transformative analytics category finds
                          application across diverse industries, spanning
                          finance, healthcare, marketing, and more, shaping
                          business strategies and fueling growth. Beyond trend
                          identification, predictive analytics constructs models
                          that simulate alternative scenarios, enabling
                          businesses to peer into the potential outcomes of
                          various choices before taking the plunge.
                          <br />
                          <br />
                          By comprehending the unique needs of each client, we
                          craft bespoke models that yield insights and drive
                          unparalleled value for our customers.
                        </p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-services mb-50">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20 ">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/service/data_analytics/prescriptive_data_analytics.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">PRESCRIPTIVE DATA ANALYTICS</h4>
                        <p>
                          Prescriptive analytics, powered by artificial
                          intelligence and big data, represents the pinnacle of
                          data-driven insights. It not only predicts outcomes
                          but also prescribes actions to achieve desired
                          results. Going beyond descriptive and predictive
                          analytics, prescriptive analytics employs advanced
                          techniques like optimization and simulation modeling
                          to pinpoint the optimal course of action.
                          <br />
                          <br />
                          By harnessing the capabilities of machine learning
                          algorithms, prescriptive analytics enables businesses
                          to delve into 'what-if' scenarios, experimenting with
                          different variables to uncover the ideal solution.
                          This transformative approach empowers organizations to
                          make data-driven decisions that lead to improved
                          outcomes, cost reduction, and heightened operational
                          efficiency. At DGi WORX, our expertise in prescriptive
                          analytics equips businesses with the compass to
                          navigate the complex landscape of decision-making,
                          ensuring they arrive at the best choices for success.
                        </p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-services mb-50">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/service/data_analytics/diagonistic_data_analytics.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">DIAGNOSTIC DATA ANALYTICS</h4>
                        <p>
                          Diagnostic data analytics serves as a valuable compass
                          for businesses seeking to unravel the mysteries behind
                          past events. By scrutinizing historical data,
                          businesses can unveil the 'why' behind certain
                          outcomes, empowering them to steer future decisions
                          with newfound wisdom.
                          <br />
                          <br />
                          DGi WORX offers diagnostic data analytics services
                          meticulously crafted to aid businesses in pinpointing
                          the root causes of challenges and optimizing their
                          processes for superior outcomes.
                          <br />
                          <br />
                          With DGi WORX by your side, harness the power of
                          diagnostic data analytics to decode the past, empower
                          the present, and shape the future of your business.
                          Gain the insights needed to enhance decision-making,
                          optimize processes, and drive continuous improvement,
                          all while staying one step ahead of challenges and
                          opportunities in your industry.
                        </p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sub-services mb-10">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/service/data_analytics/descriptive_data_analytics.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">DESCRIPTIVE DATA ANALYTICS</h4>
                        <p>
                          Descriptive analytics, often the starting point in the
                          analytics journey for many businesses, forms the
                          bedrock of data-driven decision-making. At DGi WORX,
                          we employ descriptive analytics to provide a
                          comprehensive snapshot of past and present data,
                          allowing businesses to gain a clear understanding of
                          historical occurrences and current trends.
                          <br />
                          <br />
                          With DGi WORX's descriptive data analytics, businesses
                          can harness the power of historical and real-time data
                          to make sound decisions, answer critical queries, and
                          uncover valuable insights to drive their operations
                          forward.
                          <br />
                          <br />
                          Our expertise in descriptive analytics equips
                          businesses with the foundational tools needed to
                          embark on their data analytics journey. By
                          understanding the past and present, we enable
                          organizations to pave the way for a data-driven
                          future, where informed decisions are the key to
                          success.
                        </p>
                      </div>

                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Dataanalytics;
