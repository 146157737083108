import React from "react";
import Faq from "../../../../components/Faq/faq";
import Componcontact from "../../../../components/Contact/contact-compon";
import Banner from "../../../../components/Bannersection/banner";
import { BsBrush, BsBracesAsterisk, BsRocketTakeoff } from "react-icons/bs";
import { Helmet } from "react-helmet";

function Instagram() {
  const pagename = [
    {
      pagetitle: "Instagram",
      subtitle: "Instagram",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What services do you offer for Instagram?",
      answer:
        "We provide enhancement services for Instagram profiles including increasing likes, views, followers, and custom comments tailored to your content.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How long will it take to see results after I place an order?",
      answer:
        "Results typically begin to appear within 24 to 48 hours after your order has been confirmed. The complete delivery might vary depending on the size of the order.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Can I specify the type of followers or comments I receive?",
      answer:
        "Absolutely! We offer customizable options so you can target the type of followers and the kind of comments that best fit your Instagram content and audience.",
    },
    {
      Faq_id: "collapsefour",
      data_bs_target: "#collapsefour",
      question: "What information do you need from me to deliver the services?",
      answer:
        "We only need the URL of your Instagram profile or specific posts to which you want to direct the engagement. We do not require your password or any personal login details.",
    },
    {
      Faq_id: "collapsefive",
      data_bs_target: "#collapsefive",
      question:
        "What if I do not receive the service as expected?",
      answer:
        "Contact our customer support immediately if there are any issues with your order. We offer refunds or redelivery for orders that do not meet the agreed-upon specifications.",
    },
   
  ];

  return (

    <div>
      <Helmet>
        <title>
          Facebook Services | Professional Website Design and Coding |
          DGi WORX
        </title>
        <meta
          name="description"
          content="Explore DGi WORX's comprehensive web development services. We specialize in professional website design and coding, creating responsive and dynamic web solutions for businesses."
        />
        <meta
          name="keywords"
          content="DGi WORX, dgiworx, web development, website design, web coding, professional web development, responsive websites, dynamic web solutions, website developers, front-end development, back-end development, DGi WORX web development"
        />
        <meta
          property="og:title"
          content="Web Development Services | Professional Website Design and Coding | DGi WORX"
        />
        <meta
          property="og:description"
          content="Explore DGi WORX's comprehensive web development services. We specialize in professional website design and coding, creating responsive and dynamic web solutions for businesses."
        />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta property="og:url" content="https://dgiworx.in/web-design" />

        {/* Add more meta tags as needed */}
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mt-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s">
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/socialmediamarketing/instagram/logo.jpg"
                        alt=""
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/web_development/02.jpg"
                    alt=""
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt=""
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-40">
                  <span className="tp-section-sub-title">
                  Enhance Your Instagram Presence
                  </span>
                  <h2 className="tp-section-title">Instagram social boost</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      DGi WORX is at the forefront of harnessing Instagram's dynamic platform and robust engagement tools to elevate brand visibility, intensify user interaction, and ultimately, amplify sales for our clients. Leveraging our comprehensive expertise in Instagram's algorithms and precise audience targeting options, we design exceptionally successful social media campaigns tailored to align with the distinct objectives of each client.

                      <br />
                      <br />
                      Collaborate with DGi WORX to exploit the full capabilities of Instagram marketing and propel your business to unparalleled heights online.
                    </p>
                  </div>
                </div>

                <div className="tp-about-two-list">

                  <ul>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Enhancing Instagram's Algorithm
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Authentic Engagement Guaranteed
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Premium Content Creation
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Rapid and Secure Growth
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Documented Success in Engagements
                    </li>
                    <li>
                      <span>
                        <img src="assets/img/icon/check-list.png" alt="" loading="lazy" />
                      </span>
                      Observing privacy and security

                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                    <div className="tp-about-btn mr-30">
                      <a className="tp-btn" href="http://localhost:3000/project">Discover More</a>
                    </div>
  
                  </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt=""
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* <!-- about-area-end --> */}

      <section className="py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-md-12">
              <div className="tp-section text-center mb-50">
                <span className="tp-section-sub-title">
                  Facebook Experiences Beyond Expectation
                </span>
                <h2 className="tp-section-title">
                  Our Instagram Services
                </h2>
                <p>
                  Turning Your Instagram Insights into Captivating, Interactive Experiences

                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="sub-services  mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 ">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/instagram/followers.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12  ">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">



                        <h4 className="">Elevate Your Instagram Presence</h4>

                        <p>
                          As your dedicated ally in boosting your Instagram presence, we at DGi WORX are committed to navigating the path to your social media triumph. Our team of experts is driven to draw in and engage with followers who genuinely resonate with your brand. Your Instagram identity should mirror the uniqueness of your business. Specializing in devising bespoke strategies, we ensure a perfect alignment with your brand persona and goals, catering to small businesses, nonprofits, or influencers alike.
                        </p>
                        <p>
                          Let us serve as the conduit to your Instagram aspirations, bridging the gap between your ambitions and tangible outcomes. Whether you're building from the ground up or seeking to enhance your current follower base, DGi WORX possesses the insight and creativity needed to foster a vibrant community of engaged followers on Instagram.
                        </p>


                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}

                      </div>




                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/instagram/likes.jpg"
                        loading="lazy" sizes="100%"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">Amplify Your Instagram Likes</h4>
                        <p>
                          DGi WORX recognizes the vital role that likes play in amplifying engagement on your Instagram profile. Our bespoke services are crafted to meet the unique needs of each client, specializing in strategies that significantly boost the number of likes on your Instagram posts. In the realm of social media, Instagram likes are a key indicator of your content's appeal and your profile's credibility. Our personalized approach ensures that your posts connect deeply with your audience, fostering more likes and engagement. From eye-catching images to captivating captions, we assist in crafting content that stands out to Instagram users.
                        </p>
                        <p>
                          Collaborate with DGi WORX to elevate your Instagram likes and strengthen your digital footprint. Our expertise and tailored strategies are poised to uplift your brand, magnifying engagement, and propelling success on one of the most influential social media platforms.
                        </p>


                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}


                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-80">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/instagram/comments.jpg" sizes="100%"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">
                        <h4 className="">Increase Your Instagram Comments</h4>
                        <p>
                          DGi WORX excels in elevating engagement across your Instagram posts. We are committed to increasing the visibility and interaction of your content within the Instagram community. Through customized strategies, our goal is to boost the number of comments on your Instagram posts, sparking engaging conversations and deeper connections.
                          <br />
                          <br />

                          Our method for enhancing Instagram comments is designed not only to grow your post visibility but to also inspire active engagement from your audience. Utilizing precise targeting and captivating content, we make certain that your posts attract meaningful comments from people genuinely interested in your brand. Collaborate with DGi WORX to amplify your Instagram presence and nurture valuable interactions with your community.

                        </p>
                        {/* <div className="tp-btn"><a href="/contact">Chat with us</a></div> */}

                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="sub-services mb-10">
              <div className="row">
                <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                  <div className=" text-center sub-service-carde mb-20">
                    <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                      <div className="corner-border"></div>
                      <img
                        src="assets/img/socialmediamarketing/instagram/reach.jpg"
                        loading="lazy"
                      />
                      <div className="bottom-corner"></div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                  <div>
                    <div className="tp-blog-item-content">
                      <div className="tp-blog-item-title sub-service mb-30">

                        <h4 className="">Expanding Your Facebook Reach</h4>
                        <p>
                          At DGi WORX, we understand the critical nature of extending your Instagram reach and are dedicated to making enduring impacts through our specialized website development services crafted for social media integration. Our team conducts thorough analyses of your business's unique needs to create custom, user-centric websites that genuinely embody your brand’s ethos. We prioritize swift load times and universal device and browser compatibility, ensuring an impeccable user experience for your Instagram audience.

                          <br />
                          <br />
                          Leveraging the expertise of our adept designers and developers, we produce visually striking and operationally superior websites optimized for Instagram. Acknowledging the distinctiveness of every business, we invest time in understanding your goals and specifications to ensure the website we develop is a perfect fit for your brand. Our web design services are committed to mirroring your brand identity precisely and broadening your footprint on Instagram, ensuring your online presence is as dynamic and engaging as your Instagram content.
                        </p>
                        {/* <div className="tp-btn"><a href="/contact" >Chat with us</a></div> */}

                      </div>


                      <div className="tp-blog-item-btn">
                        {/* <a href="blog-details.html.htm">Read More</a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* /* ***************** */}

      <section className="feature-area py-50">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12 text-center mb-4">
              <h2 className="tp-section-title col-lg-8 col-sm-12 text-dark mx-auto">
              Enhance Your Facebook Presence
              </h2>
              <p >
              Discover the transformative power of our Instagram Social Boost services through real success stories.  </p>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 text-center mb-4">
              <div className="social-carde">
              <h4 className="tp-section-sub-title" style={{ color: "black", fontWeight: "bold" }} >
                Before
              </h4>
              <img src="/assets/img/socialmediamarketing/instagram/before-1.png" style={{ width: "100%", display: "block", margin: "0 auto" }} alt="Before" />
              </div>
            </div>
            <div className="col-lg-6 text-center mb-4">
              <div  className="social-carde">
              <h4 className="tp-section-sub-title" style={{ color: "black", fontWeight: "bold" }}>
                After
              </h4>
              <img src="/assets/img/socialmediamarketing/instagram/after-1.png" style={{ width: "100%", display: "block", margin: "0 auto" }} alt="After" />
              </div>

            </div>
          </div>
        </div>
      </section>


















      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Instagram;
