import React from 'react';
import Banner from '../../components/Bannersection/banner';
import {Helmet} from "react-helmet";

function Privacypolicy() {
    const pagename = [
        {
          pagetitle: "Privacy Policy",
          subtitle:"Privacy",
        }
      ]
      
  return (
    <div>
        <Helmet>
    <title>Privacy Policy | DGi WORX Australia</title>
    <meta name="description" content="Read DGi WORX's Privacy Policy for comprehensive information on data protection practices in Australia. Learn about our commitment to safeguarding your privacy, complying with Australian privacy laws, and ensuring secure handling of personal information." />
    <meta name="keywords" content="privacy policy, DGi WORX,
    data protection, Australian privacy laws, personal information security,
     privacy practices, DGi WORX privacy, privacy compliance, online privacy,
      Australian data protection" />
    <meta name="geo.country" content="Australia" />
    <meta name="geo.placename" content="Piara Waters, Western Australia" />
    <meta property="og:locale" content="en_au" />
    <meta property="og:title" content="Privacy Policy | Data Protection in Australia | DGi WORX" />
    <meta property="og:description" content="Read DGi WORX's Privacy Policy for comprehensive information on data protection practices in Australia. Learn about our commitment to safeguarding your privacy, complying with Australian privacy laws, and ensuring secure handling of personal information." />
    <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg" />
    <meta property="og:url" content="https://dgiworx.com.au/privacy_policy" />
    
    {/* Add more meta tags as needed */}
</Helmet>

         <Banner items={pagename} />

         <section className='Privacy'>
                <div className="container">
                    <div className='Privacypolicy '>
                        <h2>Privacy Policy</h2>
                        <b>This privacy statement explains what personal data DGi WORX collects from you and how we use that data.</b>
                        <p>Last updated: April 29, 2023</p>
                        
                    </div>
                    <div className='Privacypolicy '>
                        <h2>Who We Are</h2>
                       
                        <p>DGi WORX is in the league of top market leaders in the I.T. & Digital Services Industry. We deliver technology solutions beyond your expectations. We have specialized teams for each of the IT & Digital Services. We are strongly committed to deliver you great experience while you are surfing our website, to share the materials you might be interested in, to promptly clarify any questions that might pop up and to ensure uninterrupted functioning of the website so that you don’t feel annoyed. This won’t be possible without your kind assistance, which you render by sharing your personal data with us. At the same time, we understand that personal data is an extremely sensitive matter and we take your privacy very seriously. 
                            To avoid making you feel uneasy in this regard, we’ve developed this privacy policy, which dwells on every little detail that refers to our work with your personal data.</p>
          
                    </div>
                    <div className='Privacypolicy '>
                        <h2>What Personal Data We Collect</h2>
                      
                        <p>DGi WORX collects data to provide you with direct feedback in respect of our services. You provide some of this data directly, such as when you fill in the Contact us form, Contact form and Ask author forms to contact us for support or comment on our articles. 
                            We get some data using technologies like cookies, and receiving error reports or usage data from software running on your device.</p>
                        
                         <p>We also obtain data from third parties. We protect data obtained from third parties according to the practices described in this statement, plus any additional restrictions imposed by the source of the data. These third-party sources may vary over time, but currently include:</p>

                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Service providers that help us determine a location based on your IP address in order to customize certain products to your location.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Partners with which we offer co-branded services or engage in joint marketing activities.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Publicly-available sources such as open government databases or other data in the public domain.</li>
                        </ul>
                        <p>You have choices about the data we collect. When you are asked to provide personal data, you may decline. But if you choose not to provide data that is necessary to deliver a service or a feature or give feedback, you may not be able to use this service or feature or receive feedback.</p>

                        <p>The data we collect can include the following:
                        <p><b>Name and contact data</b> We collect your first and last name, work email address, phone number and other similar contact data.</p>
                        </p>
                    
                        <p><b>Website data</b> We collect data about how you interact within DGi WORX website. For example, we collect:</p>
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Details of visitor behaviour patterns, i.e. data about the website sections you visit and how much time you spend there.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Configuration data. We collect data about the network you use to connect to our website. It includes your IP address.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Error reports and performance data. We collect data about problems you experience with our services. This data helps us to diagnose problems and to provide solutions. Depending on your browsing environment and settings, error reports can include such data as the type or the severity of the problem,
                             details of the software or the hardware related to the error, the contents of the files you were using when the error occurred, as well as the data about other software on your device.</li>
                        
                             <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Troubleshooting and Help Data. In case DGi WORX is engaged in troubleshooting and help, we collect data about you and your hardware, software, and other details related to the incident. Such data includes the contact data, the content of your chats and other communications with DGi WORX, the data about the condition of the 
                             machine and the application when the fault occurred and during diagnostics, and the system and registry data about software installations and hardware configurations.</li>
                        </ul>

                        <p><b>Please, be aware you may communicate personal data we do NOT require, including your payment data, in the description section of a CONTACT-US form. We will not use this information in any way, besides storing it at our backup server. Please, do not share with us any personal data not requested from you directly.</b></p>

                        <p>Our website is not intended for children and for private individuals and we do not knowingly collect data relating to them. Parents can revoke the consent choices previously made, and review, edit or request the deletion of their children's personal data.</p>
                   
                        <p>We may also collect personal data from third parties who have your consent to pass your details to us, or from publicly available sources.</p>
                   
                    </div>
                    
                    <div className='Privacypolicy '>
                        <h2>Why We Collect Personal Data</h2>
                        
                        <p>DGi WORX collects the data for the following purposes: sending communications, including promotional communications; advertising; analyzing website visitor statistics; technical website troubleshooting; improving website user experience.</p>

                        <p>In carrying out these purposes, we intend to tell you about issues you’ve asked us to tell you about; to contact you if we need to obtain or provide additional information; to make sure our records are right and to check every now and then that you’re happy and satisfied. We don't rent or trade email lists with other organisations and businesses.</p>

                        <p>However, to enhance privacy, we have built in technological and procedural safeguards designed to prevent data leaks. For example, we store data we collect from you indirectly or directly, such as your name, email address or phone number, in a secure database.</p>

                        <p>Additionally, you can sign up for email subscriptions and choose whether you wish to receive promotional communications from DGi WORX by email, SMS, physical mail, and telephone.</p>
                        
                    </div>

                    <div className='Privacypolicy '>
                        <h2>Why We Share Personal Data</h2>
                        
                        <p>We will share your information with our Development Center, which will handle your request. Our Development Center must abide by our data privacy and security requirements and is not allowed to use personal data it receives from us for any purposes other than specified above. To ensure an adequate 
                            level of protection of personal data you share, all obligations of the Development Center are established in an agreement between DGi WORX and the Development Center.</p>

                        <p>We will access, transfer, disclose, and preserve personal data, when we have a good faith belief that doing so is necessary to:</p>
                        
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Comply with the applicable law or respond to a valid legal process, including in response to a request from law enforcement or other government agencies.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Protect our customers, for example, to prevent spam or attempts to defraud users of our services, or to help prevent the loss of life or serious injury of anyone.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Protect the rights of DGi WORX, including enforcing the terms governing the use of the services.</li>
                        </ul>
                        <p>If third-party agents process personal data on our behalf in a manner inconsistent with the principles contained herein, we remain liable unless we prove we are not responsible for the event giving rise to the damage.</p>

                        <p>Please, note that some of our website pages include links to products of third parties whose privacy practices may differ from those of DGi WORX. If you provide personal data to any of those parties, your data is governed by their privacy statements.</p>
                        
                    </div>


                    <div className='Privacypolicy '>
                        <h2>What Rights You Have</h2>
                        
                        <p>You are entitled to view, amend, or delete the personal information that we hold:</p>
                        
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>You may ask what personal data about you we hold and who the recipients of your personal data are, at any time.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>You may ask us to update and correct any out-of-date or incorrect/incomplete personal data that we hold about you free of charge.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>You may opt out of any marketing communications that we may send you. This choice does not apply to mandatory service communications.</li>
                        
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>You may recall your consent for the processing of your information at any time.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>You may request erasing or updating your personal information.</li>
                        
                        </ul>
                        <p>Email your request to our mail and we will respond to requests to access or delete your personal data within 30 days.</p>

                        
                    </div>


                    <div className='Privacypolicy '>
                        <h2>Payment</h2>
                        
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>We use Zoho Invoice for billing and integrated same with Razorpay and PayPal for processing payments.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>We/Razorpay do not store your card data on their servers.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>The data is encrypted through the Payment Card Industry Data Security Standard (PCI-DSS) when processing payment.</li>
                        
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Your purchase transaction data is only used as long as it is necessary to complete your purchase transaction. After that is complete, your purchase transaction information is not saved.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Our payment gateway adheres to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.</li>

                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>PCI-DSS requirements help ensure the secure handling of credit card information by our store and its service providers.</li>

                        </ul>
                        <p>For more insight, you may also want to read terms and conditions of razorpay on <a href="https://razorpay.com/" target="_blank">https://razorpay.com</a></p>

                        
                    </div>

                    <div className='Privacypolicy '>
                        <h2>Cookies & Similar Technologies</h2>
                        
                        <p>DGi WORX uses cookies (small text files placed on your device) and similar technologies to provide our online services and to help collect data. The text in a cookie often consists of a string of numbers and letters that uniquely identifies your computer, but it can contain other information as well.</p>
                        
                        <p>We use cookies and similar technologies for several purposes, including:</p>
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span><b>Security</b> We use cookies to detect fraud and abuse of our website and services.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span><b>Storing information you provide on a website</b> When you provide information, we store the data in a cookie to remember the information you have added.</li>
                            
                        </ul>
                        <p>The main reason we typically set cookies is to gather website statistics.</p>

                        
                    </div>

                    <div className='Privacypolicy '>
                        <h2>How to Control Cookies:</h2>
                        
                        <p>You may usually switch off cookies in your browser.</p>
                           
                    </div>

                    <div className='Privacypolicy '>
                        <h2>Other Similar Technologies:</h2>
                        
                        <p>In addition to standard cookies, we can use other similar technologies to store and read data files on your computer. This is typically done to improve speed and performance by storing certain files locally. Similar to standard cookies, these technologies 
                            can also be used to store a unique identifier for your computer, which can then be used to track behavior. These technologies include Local Shared Objects (or "Flash cookies").</p>
                           
                    </div>

                    <div className='Privacypolicy '>
                        <h2>Notice to End Users</h2>
                        
                        <p>DGi WORX is intended to deliver its services to organizations. Your use of DGi WORX’s website may be subject to your organization's policies, if any. If your organization is administering your use of DGi WORX’s website, please direct your privacy inquiries to your administrator. DGi WORX is not responsible for the privacy or security practices of our customers, which may differ from those set forth in this privacy statement.</p>

                        <p>If you use an email address provided by an organization you are affiliated with, such as an employer or school, the owner of the domain (e.g., your employer) associated with your email address may access and process your data, including the contents of your communications and files.</p>
                           
                    </div>

                    <div className='Privacypolicy '>
                        <h2>How We Secure Your Data</h2>
                        
                        <p>We use a variety of security technologies and procedures to help protect your personal data from unauthorized access, use or disclosure. For example, we store the personal data you provide on computer systems that have limited access and are in controlled facilities.</p>
                           
                    </div>

                    <div className='Privacypolicy '>
                        <h2>For How Long the Data Will Be Stored</h2>
                        
                        <p>DGi WORX retains personal data for as long as necessary to provide the services and the feedback you have requested, or for other essential purposes, such as complying with our legal obligations, enforcing our agreements. Because these needs can vary for different data types, actual retention periods may vary. The criteria used to determine the retention periods include:</p>
                        
                        <ul>
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Is the personal data of a sensitive type? If so, a shortened retention time would generally be appropriate, which amounts to up to five years.</li>
                            
                            <li><span><img src="assets/img/icon/check-list.png" alt="" loading='lazy' /></span>Is DGi WORX subject to a legal, contractual, or similar obligation to retain the data? Examples can include mandatory data retention laws in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.</li>
                            
                        </ul>
                    </div>


                    <div className='Privacypolicy '>
                        <h2>How We Change this Privacy Policy</h2>
                        
                        <p>We will update this privacy statement when necessary to amend its terms or reflect customer feedback. When we post changes to this statement, we will revise the "last updated" date at the top of the statement. If there are material changes to the statement, we will notify you either by prominently posting a notice of such changes before they take effect or by directly sending you a notification.</p>
                           
                    </div>


                    <div className='Privacypolicy '>
                        <h2>How to Contact Us</h2>
                        
                        <p><b>If you have a privacy concern, complaint, or question, please email us. We will respond within 3 days.</b></p>
                           
                    </div>

                    
                </div>
            </section>
    </div>
  )
}

export default Privacypolicy;