import React from 'react';
import { BsBrush, BsPersonVcard, BsUniversalAccess, BsPalette, BsPcDisplayHorizontal } from "react-icons/bs";
import { FaRegFileCode, FaLaptopCode } from "react-icons/fa";
import { TbPhotoEdit, TbWorldSearch } from "react-icons/tb";
import { MdOutlineWifiCalling3 } from "react-icons/md";
import { useState } from 'react';
import { CiViewList } from "react-icons/ci";
import Banner from '../../components/Bannersection/banner';
// import Studentreview from '../../components/Studentreview/studentreview';
import Faq from '../../components/Faq/faq';
import Boxcard from '../../components/Cardesection/boxcard';
import {Helmet} from "react-helmet";
;


function NDIS() {

    const pagename = [
        {
            pagetitle: "NDIS Website Design and Development",
            subtitle: "NDIS",
        }
    ]
    const faqitem = [
        {
            Faq_id: "collapseone",
            data_bs_target: "#collapseone",
            question: "What makes NDIS website development different from regular website development?",
            answer: "NDIS website development is tailored to meet the unique needs of National Disability Insurance Scheme providers. It involves creating websites that prioritize accessibility, user-friendliness, and alignment with NDIS guidelines."
        },
        {
            Faq_id: "collapsetwo",
            data_bs_target: "#collapsetwo",
            question: "How can a customized website benefit my NDIS organization?",
            answer: "A customized NDIS website ensures that your online presence reflects the specific services and values of your organization. It enhances visibility, streamlines communication, and facilitates engagement with participants, creating a seamless experience."
        },
        {
            Faq_id: "collapseThree",
            data_bs_target: "#collapseThree",
            question: "What features are essential for NDIS website development?",
            answer: "Essential features include accessibility widgets, participant referral forms, privacy policies, and optimized content. These ensure that your website complies with NDIS standards while providing a positive and inclusive user experience."
        },
        {
            Faq_id: "collapseFour",
            data_bs_target: "#collapseFour",
            question: "Do you provide SEO services for NDIS websites?",
            answer: "Yes, our IT services company offers SEO optimization for NDIS websites. We implement strategies to enhance online visibility, improve search engine rankings, and make your services easily discoverable within the NDIS community."
        },
        {
            Faq_id: "collapseFive",
            data_bs_target: "#collapseFive",
            question: "Can you explain the importance of an accessibility widget in NDIS web design?",
            answer: "An accessibility widget is crucial for ensuring inclusivity by providing features that cater to diverse needs. It aligns with the principles of the National Disability Insurance Scheme, creating a more user-friendly experience for all visitors."
        },
        {
            Faq_id: "collapseSix",
            data_bs_target: "#collapseSix",
            question: "How long does it typically take to develop an NDIS website?",
            answer: "The timeline for NDIS website development varies based on the complexity and specific requirements of the project. Our team works efficiently to deliver high-quality websites within agreed-upon timeframes."

        },
        {
            Faq_id: "collapseSeven",
            data_bs_target: "#collapseSeven",
            question: "Do you provide ongoing maintenance and support for NDIS websites?",
            answer: "Yes, we offer ongoing maintenance and support services to ensure the continued smooth operation of NDIS websites. This includes updates, security measures, and assistance with any technical issues that may arise."
        },
        {
            Faq_id: "collapseEight",
            data_bs_target: "#collapseEight",
            question: "Can you integrate participant referral forms into NDIS websites?",
            answer: "Absolutely. Our NDIS website development includes the seamless integration of participant referral forms, streamlining the process and enhancing the accessibility of your services."
        },
    ];


    const ndisservice = [
        {
            icons: BsPcDisplayHorizontal,
            title: "New Website Design",
            description: "Elevate your NDIS presence with our specialized new website design services, seamlessly combining aesthetics and functionality tailored for the unique requirements of disability services."
        },
        {
            icons: FaLaptopCode,
            title: "Re-Design Website",
            description: "Revitalize your NDIS website with our redesign services, ensuring enhanced appeal, user-friendly navigation, and alignment with the unique needs of NDIS participants for an impactful online presence."
        },
        {
            icons: FaRegFileCode,
            title: "Custom Web Development",
            description: "Tailor-made for NDIS providers, our custom web development ensures robust and scalable solutions crafted to address the distinctive challenges and requirements of disability service delivery."
        },
        {
            icons: BsUniversalAccess,
            title: "Accessibility Widget",
            description: "Empower inclusivity with our tailored accessibility widget, meticulously crafted to meet the unique needs of the NDIS community, ensuring comprehensive digital inclusiveness."
        },
        {
            icons: TbWorldSearch,
            title: "SEO",
            description: "Boost your NDIS online visibility with our specialized SEO services, strategically optimizing your website to ensure it ranks higher in searches related to disability services."
        },
        {
            icons: BsBrush,
            title: "Logo Design",
            description: "Establish a distinctive NDIS brand identity with our logo design services, creating visual representations that resonate specifically within the disability services landscape."
        },
        {
            icons: BsPersonVcard,
            title: "Business Card Design",
            description: "Make your NDIS networking memorable with professionally designed business cards reflecting your commitment to disability care, ensuring a lasting impression."
        },
        {
            icons: TbPhotoEdit,
            title: "Brochure design",
            description: "Make an impact in the NDIS sector with our Brochure design services, creating visually appealing and compliant Brochure that effectively showcase your NDIS services."
        },

       
        {
            icons: BsPalette,
            title: "Graphic Design for NDIS",
            description: "Enhance your NDIS offerings through our graphic design expertise, shaping logos and promotional materials to establish a powerful visual identity designed for disability services."
        },
       

    ]

    const company = {
        name: "NDIS Website Design",
        url: "https://dgiworx.com.au/ndis-website-design",
        logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
        contactType: "customer support",
        telephone: "+61 0436291143",
        areaServed: "AU",
        sameAs: [
          "https://www.facebook.com/dgiworx",
          "https://www.instagram.com/dgiworx/",
          "https://www.behance.net/dgiworx",
          "https://www.linkedin.com/company/67087770/",
        ],
      };
      const schemaOrgJSONLD = {
        "@context": "https://schema.org",
        "@graph": [
          {
            "@type": "Organization",
            name: company.name,
            url: company.url,
            logo: company.logo,
            contactPoint: {
              "@type": "ContactPoint",
              contactType: company.contactType,
              telephone: company.telephone,
              areaServed: company.areaServed,
            },
            sameAs: company.sameAs,
          },
          // ...websiteservices.map((service) => ({
          //   "@type": "Service",
          //   name: service.name,
          //   description: service.description,
          //   provider: {
          //     "@type": "Organization",
          //     name: company.name,
          //   },
          // })),
        ],
      };

    return (
        <div>
            <Helmet>
                <title>NDIS Website Design | DGi WORX</title>
                <meta name="description" content="Expert NDIS website design tailored for NDIS providers in Australia. Reach your audience with accessible and intuitive web solutions." />
                <meta name="keywords" content="ndis web design, web design for ndis providers, ndis seo, graphic designer for ndis provider" />
                <meta name="geo.country" content="Australia" />
                <meta name="geo.placename" content="Piara Waters, Western Australia" />
                <meta property="og:locale" content="en_au" />
                <meta property="og:title" content="NDIS Website Design | DGi WORX" />
                <meta property="og:description" content="Expert NDIS website design tailored for NDIS providers in Australia. Reach your audience with accessible and intuitive web solutions." />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="/assets/img/dgiworx-home-embedded.jpg" />
                <meta property="og:url" content="https://dgiworx.com.au/ndis-website-design" />
                <script type="application/ld+json">
                    {JSON.stringify(schemaOrgJSONLD)}
                </script>
                {/* Add more meta tags as needed */}
            </Helmet>

            {/* <!-- breadcrumb-area-start --> */}

            <Banner items={pagename} />

            {/* <!-- breadcrumb-area-end --> */}



            {/* <!-- about-area-start --> */}
            <section className="about-area tp-about-two py-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="tp-about-two-wrap mb-60 wow fadeInRight" data-wow-duration="1s" data-wow-delay=".4s">
                                <div className="tp-about-two-thumb">
                                    <div className='tp-about-circle'>
                                        <div className='tp-about-circle-two'>
                                            <img src="assets/img/ndis/about-ndis.jpg" alt="NDIS Website Design and Development" loading='lazy' />
                                        </div>
                                    </div>

                                </div>
                                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                                    <div className="tp-thumb-common-overlay wow"></div>
                                    <img src="assets/img/dgignite/dgignite_2.jpg" alt="dgignite_2" loading='lazy' />
                                </div>
                                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                                    <img src="assets/img/about/two/dote.png" alt="dote.png" loading='lazy' />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="tp-about-two-wrapper mb-60 wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".4s">
                                <div className="tp-section mb-40">
                                    <span className="tp-section-sub-title">Optimizing Online Engagement for NDIS Providers</span>
                                    <h2 className="tp-section-title">Enhancing NDIS Digital Presence</h2>
                                    <div className="tp-section-title-wrapper">
                                        <p>Specializing in NDIS web design, DGi WORX enhances your online presence by creating inclusive platforms tailored for NDIS service providers. Our ndis digital marketing strategies are designed to effectively showcase your NDIS services, 
                                            ensuring your website reaches its target audience.
                                        </p>
                                        <p>We focus on user-friendly interfaces and impactful SEO content, critical for NDIS providers. Our services include streamlined participant referral forms and essential accessibility features. 
                                            Our comprehensive packages also cover complaints & feedback pages, privacy policies, and terms of use, all optimized for NDIS SEO to maximize your digital engagement.
                                            {/* Partner with us to develop a website that not only mirrors your brand but also serves as a valuable resource for individuals seeking NDIS support. 
                                             Explore our services today and take the initial step toward leaving a lasting impact within the NDIS community. */}
                                        </p>
                                    </div>
                                </div>
                                <div className="tp-about-two-list">
                                    <ul>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Deep Understanding of NDIS" loading='lazy' /></span>Deep Understanding of NDIS</li>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Empowering Caretakers" loading='lazy' /></span>Empowering Caretakers</li>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Tailored to NDIS Service" loading='lazy' /></span>Tailored to NDIS Service</li>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Enhanced Digital Experience" loading='lazy' /></span>Enhanced Digital Experience</li>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Customized Web Design" loading='lazy' /></span>Customized Web Design</li>
                                        <li><span><img src="assets/img/icon/check-list.png" alt="Web Accessibility Standards" loading='lazy' /></span>Web Accessibility Standards</li>
                                    </ul>
                                </div>
                                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tp-about-two-shape">
                    <div className="tp-about-two-shape-4">
                        <img src="assets/img/about/two/about-2-shape-2.png" alt="about-2-shape-2.png" loading='lazy' />
                    </div>
                </div>
            </section>
            {/* <!-- about-area-end --> */}

            {/* <!-- feature-area-start --> */}

            <section className="feature-area tp-feature-5-wrap py-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="tp-section text-center mb-50">
                                <span className="tp-section-sub-title">Crafting Digital Success for NDIS Services</span>
                                <h2 className="tp-section-title">NDIS Web Design & Marketing Solutions</h2>
                                <p>Tailored web design and strategic marketing empowering NDIS providers.
                                    Elevate your online presence and amplify impact with personalized digital solutions.</p>
                            </div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="">
                            <div className='sub-services mb-50'>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <div className=" text-center sub-service-carde mb-20" >

                                            <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                                                <div className="corner-border"></div>
                                                <img src="assets/img/ndis/services1.jpg" alt="ndis web design" loading='lazy' />
                                                <div className="bottom-corner"></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12">
                                        <div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-title sub-service mb-30">
                                                    <h3 className="">Professional NDIS Web Design Services</h3>
                                                    <p>Discover the benefits of our Professional NDIS Web Design Services at DGi WORX, designed to enhance your online presence and make a significant impact in the digital world. We understand the unique needs of NDIS organizations and specialize in ndis website design, creating visually appealing, user-friendly, and accessible websites specifically for service providers.</p>
                                                    {/* <br></br><br /> */}
                                                    <p>Our experienced team at DGi WORX expertly combines creativity with functionality, ensuring that your website not only reflects your brand's essence but also effectively communicates your commitment to delivering exceptional NDIS services. 
                                                        Embark on a journey of possibilities with our tailored web design solutions. 
                                                        Take the first step towards establishing a prominent online identity that resonates with your target audience and highlights your organization's dedication to excellence in the NDIS sector. Explore the vast potential of our services and witness your online presence transform into a compelling and impactful digital experience.</p>
                                                </div>

                                                <div className="tp-blog-item-btn">
                                                    {/* <a href="blog-details.html.htm">Read More</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='sub-services mb-50'>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-5 col-sm-12 order-md-2">
                                        <div className=" text-center sub-service-carde mb-20" >

                                            <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                                                <div className="corner-border"></div>
                                                <img src="assets/img/ndis/services2.jpg" loading='lazy' alt='ndis digital marketing'/>
                                                <div className="bottom-corner"></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                                        <div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-title sub-service mb-30">
                                                    <h3 className="">Explore Our NDIS Digital Marketing Services</h3>
                                                    <p>DGi WORX goes beyond conventional website design to offer a complete suite of digital marketing services crafted exclusively for NDIS Providers. Our services include advanced Social Media Management and other digital marketing strategies, each designed to fully harness the potential of your NDIS offerings.</p>
                                                    <p>Our meticulous digital marketing approaches are focused on enhancing your online visibility and driving more organic engagement, thereby improving your position within the digital landscape. With our Social Media Management services, we tap into the power of various platforms to effectively engage your target audience, creating and sharing compelling content that strategically showcases your NDIS services for maximum community impact. Embark on a journey with us to transform your online presence, propelling your organization to new heights and ensuring a lasting, influential digital footprint in the NDIS sector.</p>
                                                </div>

                                                <div className="tp-blog-item-btn">
                                                    {/* <a href="blog-details.html.htm">Read More</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='sub-services mb-50'>
                                <div className='row'>
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <div className=" text-center sub-service-carde mb-20" >

                                            <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                                                <div className="corner-border"></div>
                                                <img src="assets/img/ndis/services3.jpg" loading='lazy' alt='web design for ndis providers'/>
                                                <div className="bottom-corner"></div>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-lg-8 col-md-7 col-sm-12">
                                        <div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-title sub-service mb-30">
                                                    <h3 className="">Custom NDIS Web Design Services</h3>
                                                    <p> DGi WORX commited to excellence is embodied in our "Custom NDIS Web Design Services.
                                                        " Tailored specifically for your unique needs, our services transcend the ordinary, setting your organization apart in the expansive digital landscape. Recognizing that one size
                                                        doesn't fit all, our dedicated team collaborates closely with you to craft a website that seamlessly aligns with your distinct brand identity and specialized service offerings.</p>
                                                    <p>Whether you seek a modern, user-friendly interface, advanced accessibility features, or a streamlined participant referral system, we bring your vision to life with expertise and
                                                        precision. At DGi WORX, we go beyond meeting expectations; we aim to exceed them.
                                                        Choose us for personalized, responsive, and visually stunning NDIS web design services that not only meet but surpass your expectations,
                                                        leaving a profound and lasting impression on your audience. Your unique journey in the digital sphere begins here with a web presence as distinctive as your organization itself.
                                                    </p>
                                                </div>

                                                <div className="tp-blog-item-btn">
                                                    {/* <a href="blog-details.html.htm">Read More</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='sub-services mb-50'>
                                <div className='row'>
                                    <div className="col-lg-8 col-md-7 col-sm-12">
                                        <div>
                                            <div className="tp-blog-item-content">
                                                <div className="tp-blog-item-title sub-service mb-30">
                                                    <h3 className="">Enhance Your Reach with our NDIS SEO Services</h3>
                                                    <p>Elevate your online visibility with DGi WORX’s specialized NDIS SEO services. Our tailored approach is specifically designed to meet the unique digital challenges faced by NDIS providers. We focus on optimizing your website’s structure, content, and user experience to attract and engage more visitors. Our strategies are aimed at ensuring your site is both accessible and appealing to your target audience.</p>
                                                    <p> 
                                                    By implementing cutting-edge SEO techniques and staying compliant with the latest search engine algorithms, we guarantee that your NDIS services are more discoverable to those who need them most. Partner with us to enhance your search engine rankings and drive meaningful traffic to your site. This strategic approach not only increases engagement but also facilitates growth within the NDIS sector, helping you achieve a sustainable and impactful online presence.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-5 col-sm-12">
                                        <div className=" text-center sub-service-carde mb-20" >

                                            <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                                                <div className="corner-border"></div>
                                                <img src="assets/img/ndis/ndisseo.jpg" loading='lazy' alt='ndis seo'/>
                                                <div className="bottom-corner"></div>
                                            </div>

                                        </div>
                                    </div>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <section className="solve-area tp-solve-overlay py-3 ndissection">
                <div className="tp-solve-bg" data-background="assets/img/ndis/ndisbannercard"></div>
                <div className="container py-2">
                    <div className="row ndisblock">
                        <div className="col-sm-12 col-md-2 ndisimg">
                            <a href="#" className="tp-solve mb-60 wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s" >
                                <img src="assets/img/ndis/wenadis.svg" loading='lazy' />
                            </a>
                        </div>
                        <div className="col-sm-12 col-md-10 ndiscontent">
                            <p className='section-sub-title '>Enhancing your NDIS journey with our expert web solutions. Contact Us to start transforming your digital presence today!</p>
                            <a className="tp-btn bg-black" href="/contact"> <MdOutlineWifiCalling3 />Get In Touch</a>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- solve-area-end --> */}

            {/* <!-- feature-area-start --> */}
            <section className="feature-area py-50 p-relative">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10 col-md-12">
                            <div className="tp-section text-center mb-50">
                                <span className="tp-section-sub-title">Elevating Possibilities</span>
                                <h2 className="tp-section-title">Explore What We Offer</h2>
                                <p> Explore our comprehensive range of offerings designed to address your unique needs and empower your business for success.</p>
                            </div>
                        </div>
                    </div>
                    <Boxcard item={ndisservice} />
                </div>
                <div className="tp-feature-shape">
                    <div className="tp-feature-shape-one w-img">
                        <img src="assets/img/feature/one/features-bg.png" alt="Elevating Possibilities" loading="lazy" />
                    </div>
                </div>
            </section>
            {/* <!-- feature-area-end --> */}


            {/* <!-- dgi---course--type --> */}
            {/* <section className="feature-area tp-feature-3-bg py-50" data-background="assets/img/feature/three/feature-3-bg-1.jpg" >
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="dgi-course-type-grid">
                                <div>
                                    <div className="dgi-course-type text-center wow bounceIn" data-wow-duration=".6s" data-wow-delay=".6s">
                                        <div className="tp-feature-3-item-icon dgi-course-type-image">
                                            <img src="assets/img/dgignite/dgigniteclass/classroom.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className='dgi-course-item-Title'>
                                            <h4 className="tp-feature-3-item-title">Classroom Training</h4>
                                            <button className="tp-btn">
                                                <a href='/contact'>Enquire Now</a>
                                            </button>
                                        </div>
                                        <div className="dgi-course-item-content">
                                            <ul>
                                                <li>Our Classroom Training option offers a traditional learning experience where students gather in a physical classroom setting. It provides a collaborative
                                                    environment for face-to-face interaction with instructors and peers, allowing for immediate feedback and hands-on learning.</li>
                                            </ul>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>




                                <div className="">
                                    <div className="dgi-course-type text-center  wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                                        <div className="tp-feature-3-item-icon">
                                          
                                            <img src="assets/img/dgignite/dgigniteclass/online.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className='dgi-course-item-Title'>
                                            <h4 className="tp-feature-3-item-title">Online Training</h4>
                                            <button className="tp-btn">
                                                <a href='/contact'>Enquire Now</a>
                                            </button>
                                        </div>
                                        <div className="dgi-course-item-content">

                                          
                                            <ul className='px-3 text-left'>
                                                <li>Online Training brings the classroom to your fingertips, offering flexible and convenient learning from anywhere with an internet connection. Our online
                                                    courses provide access to expert instructors, interactive materials, and a supportive online community.</li>
                                            </ul>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div className="">
                                    <div className="dgi-course-type text-center  wow bounceIn" data-wow-duration=".5s" data-wow-delay=".5s">
                                        <div className="tp-feature-3-item-icon">
                                           
                                            <img src="assets/img/dgignite/dgigniteclass/corproate.jpg" alt="" loading="lazy" />
                                        </div>
                                        <div className='dgi-course-item-Title'>
                                            <h4 className="tp-feature-3-item-title">Corporate Training</h4>
                                            <button className="tp-btn">
                                                <a href='/contact'>Enquire Now</a>
                                            </button>
                                        </div>
                                        <div className="dgi-course-item-content">
                                            
                                            <ul className='px-3 text-left'>
                                                <li>Our Corporate Training programs are tailored to meet the specific needs of organizations looking to upskill their workforce. We collaborate closely with
                                                    businesses to design customized training solutions that align with their industry, objectives, and team expertise. </li>
                                            </ul>
                                            <div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
               
            </section> */}




            <Faq items={faqitem} />


            {/* <Componcontact /> */}
        </div>
    )
}

export default NDIS;