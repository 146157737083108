import React from "react";
import Faq from "../../../../components/Faq/faq";
import Banner from "../../../../components/Bannersection/banner";
import { Helmet } from "react-helmet";

function Appdevelopment() {
  const pagename = [
    {
      pagetitle: "App Development",
      subtitle: "App Development",
    },
  ];
  const faqitem = [
    {
      Faq_id: "collapseone",
      data_bs_target: "#collapseone",
      question: "What platforms do you develop apps for?",
      answer:
        "We specialize in developing apps for various platforms, including iOS (Apple devices), Android, and Windows. We can also create cross-platform apps that work on multiple operating systems.",
    },
    {
      Faq_id: "collapsetwo",
      data_bs_target: "#collapsetwo",
      question: "How long does it take to develop a mobile app?",
      answer:
        "The timeline for app development varies depending on the complexity and features of the app. Simple apps may take a few months, while more complex ones can take several months to a year or more.",
    },
    {
      Faq_id: "collapseThree",
      data_bs_target: "#collapseThree",
      question: "Do you provide ongoing app maintenance and updates?",
      answer:
        "Yes, we offer app maintenance and support services. We ensure your app remains up-to-date, secure, and compatible with the latest device and operating system changes.",
    },
    {
      Faq_id: "collapseFour",
      data_bs_target: "#collapseFour",
      question: "How do you ensure app security and user data protection?",
      answer:
        "Security is a top priority. We implement robust security measures, including encryption, secure authentication, and data protection protocols, to safeguard user data and the app itself.",
    },
    {
      Faq_id: "collapseFive",
      data_bs_target: "#collapseFive",
      question:
        "Can you develop apps for both startups and established businesses?",
      answer:
        "Absolutely! We cater to startups, small businesses, and established enterprises. Our solutions are tailored to meet the unique needs and budgets of each client.",
    },
    {
      Faq_id: "collapseSix",
      data_bs_target: "#collapseSix",
      question: "What is the cost structure for app development?",
      answer:
        "The cost of app development depends on various factors, including complexity, features, platforms, and design. We provide custom quotes tailored to your specific project requirements.",
    },
    {
      Faq_id: "collapseSeven",
      data_bs_target: "#collapseSeven",
      question:
        "Can you provide examples of apps you've developed in the past?",
      answer:
        "Yes, we have a portfolio of past projects that showcase our work. We'd be happy to share relevant case studies and examples of apps we've developed for various industries.",
    },
  ];

  // Our Mobile App Development Services
  const company = {
    name: "Web Development",
    url: "https://dgiworx.com.au/appdevelopment",
    logo: "https://dgiworx.com.au/assets/img/logo/dgilogo/dgi.png",
    contactType: "customer support",
    telephone: "+61 0436291143",
    areaServed: "AU",
    sameAs: [
      "https://www.facebook.com/dgiworx",
      "https://www.instagram.com/dgiworx/",
      "https://www.behance.net/dgiworx",
      "https://www.linkedin.com/company/67087770/",
    ],
  };
  const schemaOrgJSONLD = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        name: company.name,
        url: company.url,
        logo: company.logo,
        contactPoint: {
          "@type": "ContactPoint",
          contactType: company.contactType,
          telephone: company.telephone,
          areaServed: company.areaServed,
        },
        sameAs: company.sameAs,
      },
      // ...websiteservices.map((service) => ({
      //   "@type": "Service",
      //   name: service.name,
      //   description: service.description,
      //   provider: {
      //     "@type": "Organization",
      //     name: company.name,
      //   },
      // })),
    ],
  };

  return (
    <div>
      <Helmet>
        <title>App Development | DGi WORX</title>
        <meta
          name="description"
          content="Explore DGi WORX's app development services, delivering innovative mobile app solutions in Australia. Our expertise includes iOS, Android, and cross-platform app development for businesses seeking cutting-edge technology solutions."
        />
        <meta
          name="keywords"
          content="ai powered mobile app development company, 
    amazon aws mobile app development, 
    android app and ios app development, app development, mobile app solutions, 
    iOS app development, Android app development, cross-platform app development, 
    Australian app developers, DGi WORX apps, 
    mobile application development, native app development, hybrid app development, 
    mobile app design, user experience, app development company, 
    app development services,
    custom app development, mobile app consultancy, app development solutions, 
    app development strategies,
    mobile app integration, responsive app design, scalable app solutions, user-friendly apps, 
    DGi WORX projects, 
    top app development company in Australia"
        />
        <meta name="geo.country" content="Australia" />
        <meta name="geo.placename" content="Piara Waters, Western Australia" />
        <meta property="og:locale" content="en_au" />
        <meta property="og:title" content="App Development | DGi WORX" />
        <meta
          property="og:description"
          content="Explore DGi WORX's app development services, delivering innovative mobile app solutions in Australia. Our expertise includes iOS, Android, and cross-platform app development for businesses seeking cutting-edge technology solutions."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="/assets/img/dgiworx-home-embedded.jpg"
        />
        <meta
          property="og:url"
          content="https://dgiworx.com.au/appdevelopment"
        />
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
        {/* Add more meta tags as needed */}
      </Helmet>

      {/* banner section  */}

      <Banner items={pagename} />
      {/* banner section end */}

      {/* <!-- about-area-start --> */}
      <section className="about-area tp-about-two py-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrap mb-10 wow fadeInRight"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-about-two-thumb">
                  <div className="tp-about-circle">
                    <div className="tp-about-circle-two">
                      <img
                        src="assets/img/service/app_development/01.jpg"
                        alt=" App Development "
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
                <div className="tp-about-two-thumb-shape tp-thumb-common fix">
                  <div className="tp-thumb-common-overlay wow"></div>
                  <img
                    src="assets/img/service/app_development/02.jpg"
                    alt="Mobile App Development"
                    loading="lazy"
                  />
                </div>
                <div className="tp-about-two-thumb-shape-two d-none d-md-block">
                  <img
                    src="assets/img/about/two/dote.png"
                    alt="dotes"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="tp-about-two-wrapper mb-10 wow fadeInLeft"
                data-wow-duration="1s"
                data-wow-delay=".4s"
              >
                <div className="tp-section mb-20">
                  <span className="tp-section-sub-title">
                    Pioneers in Mobile App Development
                  </span>
                  <h2 className="tp-section-title">APP DEVELOPMENT</h2>
                  <div className="tp-section-title-wrapper">
                    <p>
                      At DGi WORX, we are dedicated to delivering
                      state-of-the-art mobile application development services
                      on a global scale. We recognize the growing significance
                      of mobile apps in today's business landscape and are
                      committed to crafting tailored solutions that align with
                      our clients' unique needs.
                      <br />
                      <br />
                      Our seasoned team of developers harnesses the latest tools
                      and technologies to craft top-tier mobile applications.
                      Whether it's for iOS, Android, or Windows, we pride
                      ourselves on delivering high-quality solutions that
                      seamlessly blend cutting-edge features with user-friendly
                      interfaces. Partner with us to turn your app ideas into
                      reality. We're here to not only meet but exceed your
                      expectations.
                    </p>
                  </div>
                </div>
                <div className="tp-about-two-list">
                  <ul>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=" Platform Selection"
                          loading="lazy"
                        />
                      </span>
                      Platform Selection
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt="User-Centered Design"
                          loading="lazy"
                        />
                      </span>
                      User-Centered Design
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt="Performance Optimization"
                          loading="lazy"
                        />
                      </span>
                      Performance Optimization
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt=" Security"
                          loading="lazy"
                        />
                      </span>
                      Security
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt="Testing and Quality Assurance"
                          loading="lazy"
                        />
                      </span>
                      Testing and Quality Assurance
                    </li>
                    <li>
                      <span>
                        <img
                          src="assets/img/icon/check-list.png"
                          alt="App Store Optimization"
                          loading="lazy"
                        />
                      </span>
                      App Store Optimization
                    </li>
                  </ul>
                </div>
                {/* <div className="tp-about-details d-flex align-items-center flex-wrap">
                  <div className="tp-about-btn mr-30">
                    <a className="tp-btn" href="http://localhost:3000/about">Discover More</a>
                  </div>

                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="tp-about-two-shape">
          <div className="tp-about-two-shape-4">
            <img
              src="assets/img/about/two/about-2-shape-2.png"
              alt="App Development Services"
              loading="lazy"
            />
          </div>
        </div>
      </section>

      {/* <!-- about-area-end --> */}

      <section className="feature-area tp-feature-5-wrap py-50">
        <div className="container">
          <div className="col-lg-10 col-md-12 mx-auto">
            <div className="tp-section text-center mb-50">
              <span className="tp-section-sub-title">
                Empowering Innovation
              </span>
              <h2 className="tp-section-title">
                Our Mobile App Development Services
              </h2>
              <p>
                Explore how we can transform your app ideas into reality,
                offering tailored solutions, cutting-edge technology, and
                unwavering commitment to excellence.
              </p>
            </div>
          </div>
          <div className="row d-flex">
            <div className="">
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/app_development/android_app_development.jpg"
                          alt="ANDROID APP DEVELOPMENT"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h3 className="">ANDROID APP DEVELOPMENT</h3>
                          <p>
                            At DGi WORX, our passion lies in crafting
                            cutting-edge Android applications that redefine user
                            experiences. Our seasoned team of Android developers
                            harnesses the latest tools and technologies to
                            engineer robust and scalable applications,
                            meticulously aligned with your business goals.
                            <br />
                            <br />
                            With an in-depth understanding of the Android
                            platform, we harness the power of Java and the
                            Android framework to create innovative and
                            feature-rich applications that stand out in the
                            competitive digital landscape.
                            <br />
                            <br />
                            Our commitment goes beyond development; it extends
                            to the complete app lifecycle. From initial ideation
                            to design, development, rigorous testing, seamless
                            deployment, and ongoing maintenance, we ensure your
                            application consistently delivers exceptional value
                            to your users.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-50">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12 order-md-2 ">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/app_development/ios_app_development.jpg"
                          alt="iOS APP DEVELOPMENT"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12 order-md-1">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h3 className="">iOS APP DEVELOPMENT</h3>
                          <p>
                            At DGi WORX, we're dedicated to harnessing the full
                            potential of the iOS platform through cutting-edge
                            iPhone app development services. Our team of expert
                            iOS developers is committed to staying at the
                            forefront of the latest trends and technologies,
                            ensuring that we deliver bespoke, high-performance
                            iPhone apps that not only captivate with their
                            aesthetics but also provide a seamlessly intuitive
                            user experience. <br />
                            <br />
                            From the initial spark of ideation to the final
                            launch and ongoing maintenance, we collaborate
                            closely with our clients to ensure their vision is
                            realized in a successful iPhone application. Our
                            strong focus on quality and innovation empowers
                            businesses to achieve their digital goals and stay
                            at the forefront of their respective industries.{" "}
                            <br />
                            <br />
                            Our iPhone app development services unlock the vast
                            potential of this platform, allowing businesses to
                            reach a wider audience.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sub-services mb-10">
                <div className="row">
                  <div className="col-lg-4 col-md-5 col-sm-12">
                    <div className=" text-center sub-service-carde mb-20">
                      <div className="sub-service-carde-img tp-thumb-common fix overlay-anim ">
                        <div className="corner-border"></div>
                        <img
                          src="assets/img/service/app_development/cross_platform_app_development.jpg"
                          alt="CROSS PLATFORM APP DEVELOPMENT"
                          loading="lazy"
                        />
                        <div className="bottom-corner"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-7 col-sm-12">
                    <div>
                      <div className="tp-blog-item-content">
                        <div className="tp-blog-item-title sub-service mb-30">
                          <h3 className="">CROSS PLATFORM APP DEVELOPMENT</h3>
                          <p>
                            DGi WORX empowers you to maximize your reach and
                            enhance user engagement through our cutting-edge
                            cross-platform mobile app development services. We
                            specialize in creating top-tier mobile apps that
                            seamlessly transcend multiple platforms, including
                            iOS, Android, and Windows.
                            <br />
                            <br />
                            Our cross-platform approach opens doors to a broader
                            audience while minimizing development time and
                            costs. By harmonizing your app's functionality
                            across different devices and operating systems, we
                            ensure a consistent user experience, elevating your
                            digital presence.
                            <br />
                            <br />
                            Discover the potential of cross-platform app
                            development with DGi WORX. Let's together elevate
                            your mobile strategy and propel your business into
                            the future.
                          </p>
                        </div>

                        <div className="tp-blog-item-btn">
                          {/* <a href="blog-details.html.htm">Read More</a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* faq section */}

      <Faq items={faqitem} />

      {/* Faq page end */}

      {/* contact section */}

      {/* <Componcontact /> */}

      {/* contact end section */}
    </div>
  );
}

export default Appdevelopment;
